import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Table, Space, Modal, DatePicker, Select, message, Progress, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import '../../global.css';

const { Option } = Select;

const UserManagement = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [users, setUsers] = useState([]);
  const [empresas, setEmpresas] = useState([]); // Novo estado para armazenar empresas
  const [perfis, setPerfis] = useState([]); // Novo estado para armazenar perfis
  const [form] = Form.useForm();
  const [editingUserId, setEditingUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0); // Nível de força da senha
  const [passwordFeedback, setPasswordFeedback] = useState(''); // Feedback textual da força da senha
  const [passwordValid, setPasswordValid] = useState(false); // Validação da senha para permitir cadastro

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen); // Função para abrir/fechar a Sidebar

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  // Função para validar a senha
  const validatePassword = (value) => {
    let strength = 0;

    // Verifica se a senha tem pelo menos 8 caracteres
    if (value.length >= 8) strength += 1;

    // Verifica se a senha tem pelo menos uma letra maiúscula
    if (/[A-Z]/.test(value)) strength += 1;

    // Verifica se a senha tem pelo menos uma letra minúscula
    if (/[a-z]/.test(value)) strength += 1;

    // Verifica se a senha tem pelo menos um número
    if (/\d/.test(value)) strength += 1;

    // Verifica se a senha tem pelo menos um caractere especial
    if (/[@$!%*?&#]/.test(value)) strength += 1;

    setPasswordStrength(strength); // Atualiza o nível de força da senha
    setPasswordFeedback(getPasswordFeedback(strength)); // Atualiza o feedback textual
    setPasswordValid(strength >= 3); // Verifica se a senha é forte (mínimo de 3 pontos)
  };

  // Função para retornar o feedback textual de acordo com a força da senha
  const getPasswordFeedback = (strength) => {
    switch (strength) {
      case 1:
        return 'Senha fraca';
      case 2:
        return 'Senha moderada';
      case 3:
      case 4:
      case 5:
        return 'Senha forte';
      default:
        return 'Insira uma senha';
    }
  };

  // Função que retorna a cor do progresso com base na força da senha
  const getProgressColor = (strength) => {
    switch (strength) {
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
      case 4:
      case 5:
        return 'green';
      default:
        return '#e0e0e0'; // Cor neutra quando não há senha
    }
  };

  // Função para buscar todos os usuários
  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Mapeia os usuários adicionando o nome da empresa e a descrição do perfil
      const usersWithDetails = response.data.map(user => {
        const empresa = empresas.find(e => e.id === user.id_empresa); // Busca o nome da empresa
        const perfil = perfis.find(p => p.id === user.id_perfil); // Busca a descrição do perfil
        return {
          ...user,
          nome_empresa: empresa ? empresa.nome_empresa : 'Empresa desconhecida',
          descricao_perfil: perfil ? perfil.descricao_perfil : 'Perfil desconhecido'
        };
      });

      setUsers(usersWithDetails);
    } catch (error) {
      message.error('Erro ao buscar usuários');
    }
  }, [apiUrl, token, empresas, perfis]); // Inclui empresas e perfis como dependências

  // Função para buscar empresas (ativas ou todas)
  const fetchEmpresas = useCallback(async (ativo = true) => {
    try {
      const response = await axios.get(`${apiUrl}/api/empresas`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { ativo } // Passando o parâmetro ativo
      });
      setEmpresas(response.data);
    } catch (error) {
      message.error('Erro ao buscar empresas');
    }
  }, [apiUrl, token]);

  // Função para buscar perfis (ativos ou todos)
  const fetchPerfis = useCallback(async (ativo = true) => {
    try {
      const response = await axios.get(`${apiUrl}/api/perfis`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { ativo } // Passando o parâmetro ativo
      });
      setPerfis(response.data);
    } catch (error) {
      message.error('Erro ao buscar perfis');
    }
  }, [apiUrl, token]);

  useEffect(() => {
    fetchEmpresas(true); // Buscar somente empresas ativas para a tela de usuários
    fetchPerfis(true); // Buscar somente perfis ativos para a tela de usuários
  }, [fetchEmpresas, fetchPerfis]);

  useEffect(() => {
    if (empresas.length > 0 && perfis.length > 0) {
      fetchUsers(); // Só buscar usuários após empresas e perfis terem sido carregados
    }
  }, [empresas, perfis, fetchUsers]);


  // Exibir modal para criar ou editar usuário
  const showModal = (user = null) => {
    if (user) {
      setEditingUserId(user.id);
      form.setFieldsValue({
        ...user,
        data_nascimento: moment(user.data_nascimento),
      });
    } else {
      form.resetFields();
      setEditingUserId(null);
    }
    setIsModalVisible(true);
  };

  // Fechar modal
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    // Formatar a data de nascimento
    const userData = {
      ...values,
      data_nascimento: values.data_nascimento.format('YYYY-MM-DD'),
    };

    try {
      if (editingUserId) {
        // Atualizando um usuário existente

        // Verifica se o campo de senha foi preenchido para decidir se deve ser atualizado
        if (!values.senha) {
          // Se a senha não for informada, removê-la do objeto `userData`
          delete userData.senha;
        } else {
          // Aqui você pode fazer a validação de força da senha, se necessário
          const passwordStrength = validatePassword(values.senha); // Função que você já possui
          if (passwordStrength < 3) {
            message.error('A senha precisa ser forte para ser atualizada.');
            return;
          }
        }

        // Atualizar o usuário
        await axios.put(`${apiUrl}/api/users/${editingUserId}`, userData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success('Usuário atualizado com sucesso');

      } else {
        // Criando um novo usuário
        console.log(token);
        // Validação de senha obrigatória na criação
        const passwordStrength = 4;//validatePassword(values.senha); // Função que você já possui
        console.log("passou");
        if (passwordStrength < 3) {
          message.error('A senha precisa ser forte para o cadastro.');
          return;
        }
        console.log(userData);
        // Criar o usuário
        await axios.post(`${apiUrl}/api/users`, userData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success('Usuário cadastrado com sucesso');
      }

      fetchUsers(); // Atualiza a lista de usuários
      setIsModalVisible(false); // Fecha o modal
      form.resetFields(); // Reseta os campos do formulário
    } catch (error) {
      message.error('Erro ao salvar usuário');
    }
  };

  // Desativar usuário
  const handleDeactivate = async (id) => {
    try {
      await axios.put(`${apiUrl}/api/users/${id}/deactivate`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.success('Usuário desativado com sucesso');
      fetchUsers();
    } catch (error) {
      message.error('Erro ao desativar usuário');
    }
  };

  const columns = [
    {
      title: 'Nome de Usuário',
      dataIndex: 'nome_user',
      key: 'nome_user',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Empresa',
      dataIndex: 'nome_empresa', // Exibe o nome da empresa
      key: 'nome_empresa',
    },
    {
      title: 'Perfil',
      dataIndex: 'descricao_perfil', // Exibe a descrição do perfil
      key: 'descricao_perfil',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => showModal(record)} >Editar</Button>
          <Button onClick={() => handleDeactivate(record.id)} danger>Desativar</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="menu-container">
      {/* Adicionando o Header */}
      <Header toggleSidebar={toggleSidebar} />

      <div className="main-content">
        {/* Adicionando a Sidebar */}
        <Sidebar isOpen={isSidebarOpen} />

        <div className={`dashboard ${isSidebarOpen ? '' : 'full'}`}>
          <div>
            <h2 style={{ textAlign: 'center' }}>Gerenciamento de Usuários</h2>
            <Table columns={columns} dataSource={users} rowKey="id" style={{ marginTop: 20 }} />
            <Button type="primary" className="button" style={{ textAlign: 'right' }} onClick={() => showModal()}>Cadastrar Novo Usuário</Button>
            <Modal
              title={editingUserId ? 'Editar Usuário' : 'Cadastrar Novo Usuário'}
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, type: 'email', message: 'Por favor, insira um email válido' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nome_user"
                  label="Nome de Usuário"
                  rules={[{ required: true, message: 'Por favor, insira o nome de usuário' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nome_completo"
                  label="Nome Completo"
                  rules={[{ required: true, message: 'Por favor, insira o nome completo' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="data_nascimento"
                  label="Data de Nascimento"
                  rules={[{ required: true, message: 'Por favor, selecione a data de nascimento' }]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  name="id_empresa"
                  label="Empresa"
                  rules={[{ required: true, message: 'Por favor, selecione a empresa' }]}
                >
                  <Select placeholder="Selecione a empresa">
                    {empresas.map(empresa => (
                      <Option key={empresa.id} value={empresa.id}>
                        {empresa.nome_empresa} {/* Mostrando nome_empresa na combo */}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="id_perfil"
                  label="Perfil"
                  rules={[{ required: true, message: 'Por favor, selecione o perfil' }]}
                >
                  <Select placeholder="Selecione o perfil">
                    {perfis.map(perfil => (
                      <Option key={perfil.id} value={perfil.id}>
                        {perfil.descricao_perfil} {/* Mostrando descricao_perfil na combo */}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form layout="vertical">
                  <Form.Item
                    name="senha"
                    label={
                      <span>
                        Senha&nbsp;
                        <Tooltip title="A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira a senha',
                      },
                      {
                        validator: (_, value) => {
                          if (value) {
                            validatePassword(value);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  {/* Barra de progresso para indicar a força da senha */}
                  <Progress
                    percent={(passwordStrength / 5) * 100} // Converter a força para porcentagem
                    showInfo={false}
                    strokeColor={getProgressColor(passwordStrength)} // Definir a cor da barra
                  />

                  {/* Feedback textual da força da senha */}
                  <p>{passwordFeedback}</p>


                </Form>
                <Form.Item style={{ textAlign: 'center' }}>
                  <Button type="primary" htmlType="submit" disabled={!passwordValid}>
                    {editingUserId ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
      </div>

      {/* Adicionando o Footer */}
      <Footer />
    </div>
  );
};

export default UserManagement;