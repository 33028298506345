import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
// import Menu from './pages/Menu';
import Demo from './pages/Demo';
import UserManagement from './pages/management/UserManagement';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/menu" element={<Menu />} /> */}
        <Route path="/demo" element={<Demo />} />
        <Route path="usuarios" element={<UserManagement />} /> {/* Página de Gerenciamento de Usuários */}
        {/* Redirecionamento para rota não encontrada */}
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;