import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useLocation para pegar o estado passado pela navegação
import Header from '../components/Header';

import Sidebar from '../components/Sidebar';
import './Demo.css';

const Demo = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [biUrl, setBiUrl] = useState(''); // Estado para armazenar o endereço BI
  const navigate = useNavigate();
  const location = useLocation(); // Usado para pegar o estado passado ao navegar

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      navigate('/'); // Redireciona para login se não estiver autenticado
    } else {
      // Verifica se o estado contém o endereco_bi passado pela navegação
      const enderecoBi = location.state?.endereco_bi || ''; // Se não houver, deixa como vazio
      setBiUrl(enderecoBi); // Atualiza o estado com o endereço recebido
    }
  }, [navigate, location.state]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Alterna entre aberto e fechado
  };

  return (
    <div className="menu-container">
      <Header toggleSidebar={toggleSidebar} />
      <div className="main-content">
        <Sidebar isOpen={isSidebarOpen} />
        <div className={`dashboard ${isSidebarOpen ? '' : 'full'}`}>
          {biUrl ? (
            <iframe
              title="Power BI Report"
              width="100%"
              height="100%"
              src={biUrl}
              frameBorder="0"
              allowFullScreen={true}
              style={{ border: 'none', overflow: 'hidden' }}
              scrolling="no"
            ></iframe>
          ) : (
            <p style={{ color: 'white' }}>Selecione no Menu a esquerda um Dashboard para visualizar.</p> // Alterando a cor do texto para branco         
          )}
        </div>
      </div>
      
    </div>
  );
};

export default Demo;