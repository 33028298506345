import React from 'react';
import './Header.css';
import logo from '../assets/lg-wego.png'; // Certifique-se que o caminho está correto

const Header = ({ toggleSidebar }) => {
  return (
    <div className="header">
      <div className="hamburger-menu" onClick={toggleSidebar}>
        &#9776; {/* Ícone do menu sanduíche */}
      </div>
      <div className="header-logo">
        <img src={logo} alt="Logo" />
      </div>
    </div>
  );
};

export default Header;