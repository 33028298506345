import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const navigate = useNavigate();

  // Verifica se o usuário já está autenticado
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/demo'); // Redireciona se o token existir
    }
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, senha }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('id_empresa', data.id_empresa); 
        navigate('/demo'); // Redireciona para demo após login bem-sucedido
        message.success('Login realizado com sucesso!');
      } else {
        message.error(data.message || 'Erro ao fazer login');
      }
    } catch (err) {
      message.error('Erro ao conectar com o servidor');
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <Form onFinish={handleLogin} className="login-form" layout="vertical">
          <Form.Item
            name="email"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: 'Por favor, insira o email!' },
              { type: 'email', message: 'Por favor, insira um email válido!' },
            ]}
          >
            <Input
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="senha"
            rules={[{ required: true, message: 'Por favor, insira a senha!' }]}
          >
            <Input.Password
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Senha"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button className="login-button" type="primary" htmlType="submit" block size="large">
              Login
            </Button>
          </Form.Item>
        </Form>
        <footer className="login-footer">© 2024 - Powered by WeGoSB</footer>
      </div>
    </div>
  );
};

export default Login;