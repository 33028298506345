import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined, DashboardOutlined, SettingOutlined, UserOutlined, RightOutlined  } from '@ant-design/icons';
import axios from 'axios'; // Para fazer a requisição ao backend
import './Sidebar.css';

const Sidebar = ({ isOpen }) => {
  const navigate = useNavigate();
  const [isCadastrosOpen, setIsCadastrosOpen] = useState(false); // Estado para abrir/fechar o submenu
  const [biItems, setBiItems] = useState([]); // Estado para armazenar os itens BI dinâmicos

  // Função para buscar os dados do banco (tabela empresa_bi)
  useEffect(() => {
    const fetchBiItems = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const idEmpresa = localStorage.getItem('id_empresa'); // Obtém idEmpresa do localStorage
        const token = localStorage.getItem('token'); // Obtém token do localStorage

        const response = await axios.get(`${apiUrl}/api/empresa_bi/empresa/${idEmpresa}`, {
          // params: {
          //   nome_bi: 'DEMO'  // Passa o nome_bi como query param
          // },
          headers: {
            'Authorization': `Bearer ${token}`  // Inclui o token no cabeçalho
          }
        });

        setBiItems(response.data);  // Armazena os dados retornados no estado biItems

      } catch (error) {
        console.error('Erro ao buscar itens BI:', error);
      }
    };
    fetchBiItems(); // Chama a função quando o componente monta
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token de autenticação
    navigate('/'); // Redireciona para a página de login
  };

  const toggleCadastros = () => {
    setIsCadastrosOpen(!isCadastrosOpen); // Alterna a visibilidade do submenu
  };

  // Função para navegar para a página Demo com o endereço BI
  const handleNavigateToDemo = (endereco_bi) => {
    navigate('/demo', { state: { endereco_bi } }); // Passa o endereço_bi para a página Demo
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <nav>
        {/* Menu "Dashboard" com submenus dinâmicos */}
        <button className="menu-button">
          <DashboardOutlined style={{ marginRight: '10px', fontSize: '20px' }} /> {/* Ícone do Dashboard */}
          Dashboard
        </button>

        {/* Submenus dinâmicos carregados da tabela empresa_bi */}
        {biItems.length > 0 && (
          <div className="submenu">
            {biItems.map((item) => (
              <button
                key={item.id}
                className="menu-button submenu-item"
                onClick={() => handleNavigateToDemo(item.endereco_bi)}
              >
                {item.nome_bi} {/* O nome_bi será o texto do botão */}
                <RightOutlined style={{ marginLeft: 'auto', fontSize: '16px' }} /> {/* Ícone de seta */}
              </button>
            ))}
          </div>
        )}

        {/* Botão de Cadastros com Submenu */}
        <button className="menu-button" style={{ visibility: 'hidden' }} onClick={toggleCadastros}>
          <SettingOutlined style={{ marginRight: '10px', fontSize: '20px' }} /> {/* Ícone de Configurações */}
          Cadastros
        </button>

        {/* Submenu de Cadastros (condicionalmente visível) */}
        {isCadastrosOpen && (
          <div className="submenu">
            <button className="menu-button submenu-item" onClick={() => navigate('/usuarios')}>
              <UserOutlined style={{ marginRight: '10px', fontSize: '20px' }} /> {/* Ícone de Usuários */}
              Usuários
            </button>
          </div>
        )}
      </nav>

      {/* Botão de logout no rodapé */}
      <div className="logout-container">
        <button onClick={handleLogout} className="logout-button">
          <LogoutOutlined style={{ fontSize: '20px', marginRight: '10px' }} /> {/* Ícone de Logout */}
          Sair
        </button>
      </div>
    </div>
  );
};

export default Sidebar;